/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Link from '../../link';
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Technology Features Component.
 *
 * @param {Array} meta Meta.
 *
 * @return {jsx}
 */
const TechnologyFeatures = ({ meta }) => {

	return (
		<section className="technology-sections technology-feature">

			{meta?.map((data, index) => (
				<div className="row technology-feature__list" key={`technology-list-${index}`}>

					{data.content.map((list, index) => (
						<div className="small-12 columns technology-feature__inner-list" key={`${list.heading}-${index}`}>
							<div className="row">
								<div className="large-4 medium-6 small-12 columns">
									<h2 className="technology-feature__heading" dangerouslySetInnerHTML={{ __html: sanitize(list.heading) }} />
									<div className="technology-feature__description show-for-medium-only" dangerouslySetInnerHTML={{ __html: sanitize(list.description) }} />
								</div>
								<div className="large-4 medium-6 small-12 columns hide-for-medium-only">
									<div className="technology-feature__description" dangerouslySetInnerHTML={{ __html: sanitize(list.description) }} />
								</div>
								<div className="large-4 medium-6 small-12 columns">
									<div className="technology-feature__content" dangerouslySetInnerHTML={{ __html: sanitize(list.list) }} />
								</div>
							</div>
						</div>
					))}

					<div className="small-12 columns technology-feature__read-more">
						<Link {...data.linkUrl} withArrow={true} />
					</div>
				</div>
			))}
		</section>
	);

};

TechnologyFeatures.propTypes = {
	meta: PropTypes.array,
};

TechnologyFeatures.defaultProps = {
	meta: [
		{
			linkUrl: '',
			linkText: '',
			content: [
				{
					heading: '',
					description: '',
					list: ''
				}
			]
		}
	]
};

export default TechnologyFeatures;
