/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Link from '../../link';
import Image from '../../image/index';
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Technology Banner Component.
 *
 * @param {Object} page Page props.
 *
 * @return {jsx}
 */
const TechnologyBanner = ({ page, ctaLink, tagParent, tagName }) => {

	const { title, quote, excerpt, featuredImage } = page;

	return (
		<section className="technology-sections technology-banner">
			<div className="row">
				<div className="large-6 medium-12 small-12 columns">
					<div className="row">
						<div className="small-12 columns">
							<h1 className="technology-banner__title" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
						</div>
						<div className="large-12 medium-9 small-12 columns technology-banner__quote" dangerouslySetInnerHTML={{ __html: sanitize(quote) }} />
						<div className="large-12 medium-9 small-12 columns technology-banner__excerpt" dangerouslySetInnerHTML={{ __html: sanitize(excerpt) }} />
					</div>
				</div>
				<div className="large-6 medium-12 small-12 columns">
					{featuredImage?.large?.node ? <figure className="technology-banner__figure">
						<Image {...featuredImage?.large?.node} placeholder={featuredImage?.thumbnail?.node} className="technology-banner__image" />
					</figure> : null}
				</div>
			</div>
			<div className="row">
				<div className="large-4 medium-4 small-12 columns">
					<Link {...ctaLink} tagParent={tagParent} tagName={tagName} className="conversation-content__cta" />
				</div>
			</div>
		</section>
	);

};


TechnologyBanner.propTypes = {
	page: PropTypes.object,
	ctaLink: PropTypes.object,
	tagParent: PropTypes.string,
	tagName: PropTypes.string,
};

TechnologyBanner.defaultProps = {
	page: {
		title: '',
		quote: '',
		excerpt: '',
		featuredImage: {
			altText: '',
			sourceUrl: '',
		},
	},
	ctaLink: {
		title: '',
		url: '',
		target: ''
	},
	tagParent: '',
	tagName: '',
};

export default TechnologyBanner;
