/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import TechnologyBanner from '../../components/technology/banner';
import TechnologyFeatures from '../../components/technology/features';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
// import ConversationAdvertForm from '../../components/conversation-based-advertising/form';
import ContactForm from '@blocks/contact-form';

/**
 * Technology Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Technology = (props) => {

	const {
		pageContext: {
			page,
			page: {
				uri,
				seo,
				techonologyPageMeta: { quote, technology },
				contactUsFormMeta: { contactForm, ctaLink },
			}
		}
	} = props;

	// Get all required data in single object.
	const customPage = {
		title: page.title,
		excerpt: page.excerpt,
		quote: quote,
		featuredImage: { large: page.large, thumbnail: page.thumbnail },
		pageId: page.pageId,
		uri: uri,
	};

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title="Technology" uri={uri} className="technology-page">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<TechnologyBanner page={customPage} ctaLink={ctaLink} tagParent="TECH" tagName="Tech_Contact" />
			<TechnologyFeatures meta={technology} />
			{/* <ConversationAdvertForm meta={contactForm} tagParent="TECH" tagName="Tech_Submit" /> */}
			<ContactForm />
		</Layout>
	)
};

Technology.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};


export default Technology;
